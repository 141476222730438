import { fetchDone, fetchResponseError, receiveOffer } from './actions';
import api from './api';
import { DEFAULT_OFFER_MODULE, REQUEST_OFFER } from './constants';
import { safeProductId } from './utils';

export function offerRequestMiddleware(store) {
  return next => action => {
    if (action.type === REQUEST_OFFER) {
      const {
        merchantId,
        sessionId,
        environment: { apiUrl }
      } = store.getState();

      const productId = safeProductId(action.payload.product);
      if (productId)
        api
          .fetchOffer(
            apiUrl,
            merchantId,
            sessionId,
            productId,
            action.payload.module || DEFAULT_OFFER_MODULE,
            action.payload.searchParams
          )
          .then(
            response => store.dispatch(receiveOffer(response, action.payload.offer)),
            err => store.dispatch(fetchResponseError(err))
          )
          .finally(() => store.dispatch(fetchDone(action)));
    }
    return next(action);
  };
}
